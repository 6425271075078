export const environment = {
  production: true,
  apiUrl: `$API_URL`,
  issuer: `$ISSUER`,
  clientId: `$CLIENT_ID`,
  logoutUrl: `$LOGOUT_URL`,
  servicePortalUrl: `$SERVICE_PORTAL_URL`,
  geoserverUrl: `$GEOSERVER_URL`,
  geoserverApiVersion: '1.0.0',
  mapTilerToken: '$MAP_TILER_TOKEN',
  mapTilerBaseMapId: '$MAP_TILER_BASE_MAP_ID',
  usercentricsSettingsId: '$USERCENTRICS_SETTINGS_ID',
  environment: '$ENVIRONMENT',
  gtmId: '$GTM_ID',
  gtmAuth: '$GTM_AUTH',
  gtmPreview: '$GTM_PREVIEW',
};
